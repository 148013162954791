import "%modules%/header/header";
import "%modules%/footer/footer";

import "%modules%/slider/slider";
import "%modules%/exterior/exterior";
import "%modules%/interior/interior";
import "%modules%/configuration/configuration";
import "%modules%/option/option";
import "%modules%/comfort/comfort";
import "%modules%/security/security";

import "%modules%/form/form";
