$(function() {
    const button = $('.security__item-more');
    button.click(function() {
        link = $(this).attr('data');
        content = $('#' + link);
        content.fadeIn(700);
        $(this).toggleClass('active');
        if ($(this).hasClass('active') && button.hasClass('active')) {
            content.addClass('open');
            content.parent().parent().addClass('active');
            $(this).html(
                `<svg class="security__icon-close" width="32" height="32">
                    <use xlink:href="dist/img/sprites/sprite.svg#icon-close-red" />
                </svg>
                Закрыть`
            );
        } else {
            content.removeClass('open');
            content.parent().parent().removeClass('active');
            content.hide();
            $(this).html(
                `<svg class="security__icon-plus" width="32" height="32">
                    <use xlink:href="dist/img/sprites/sprite.svg#icon-plus-border" />
                </svg>
                Подробнее`
            );
        }
    });
})
