$(() => {
    const $comfortSlider = $(".js-slider-comfort");
    const arrows = $comfortSlider.data("arrows").split(",");

    if(!!$comfortSlider.length) {
        $comfortSlider.slick({
            slidesToShow: 3,
            arrows: false,
            infinite: true,
            centerMode: true,
            dots: false,
            // variableWidth: true,
            prevArrow: `<button type="button" class="slick-prev"><svg><use xlink:href="${arrows[0]}"></use></svg></button>`,
            nextArrow: `<button type="button" class="slick-next"><svg><use xlink:href="${arrows[1]}"></use></svg></button>`,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        dots: true,
                    },
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        dots: true,
                    },
                },
            ]
        })
    }
})
