$(() => {
    const $interiorSlider = $(".js-slider-interior");
    const arrows = $interiorSlider.data("arrows").split(",");

    if(!!$interiorSlider.length) {
        $interiorSlider.slick({
            slidesToShow: 1,
            arrows: true,
            infinite: true,
            centerMode: true,
            dots: true,
            variableWidth: true,
            prevArrow: `<button type="button" class="slick-prev"><svg><use xlink:href="${arrows[0]}"></use></svg></button>`,
            nextArrow: `<button type="button" class="slick-next"><svg><use xlink:href="${arrows[1]}"></use></svg></button>`,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                    },
                },
            ]
        })
    }

    const button = $('.interior__button');
    const bag = $('.interior__content-bag');

    button.click(function(){
        $('.interior__content-hide').slideToggle(0, function(){
            $(this).toggleClass('active');
            button.toggleClass('open');
            if (button.hasClass('open')) {
                button.html(
                    `<svg class="interior__icon-close" width="18" height="18">
                        <use xlink:href="dist/img/sprites/sprite.svg#icon-close" />
                    </svg>
                    Свернуть`
                );
                bag.addClass('hidden');
            } else {
                button.html(
                    `<svg class="interior__icon-plus" width="18" height="18">
                        <use xlink:href="dist/img/sprites/sprite.svg#icon-plus" />
                    </svg>
                    Показать весь текст`
                );
                bag.removeClass('hidden');
            }
        });
        return false;
    });

    // $('.interior__button').click(function(){
    //     $('.interior__content-hide').slideToggle(300, function(){
    //         if ($(this).is(':hidden')) {
    //             $('.interior__button').html(
    //                 `<svg class="interior__icon-plus" width="18" height="18">
    //                   <use xlink:href="dist/img/sprites/sprite.svg#icon-plus" />
    //                 </svg>
    //                 Показать весь текст`
    //             );
    //             $('.interior__button').removeClass('open');
    //             $('.interior__button').next().removeClass('active');
    //         } else {
    //             $('.interior__button').html(
    //                 `<svg class="interior__icon-close" width="18" height="18">
    //                   <use xlink:href="dist/img/sprites/sprite.svg#icon-close" />
    //                 </svg>
    //                 Свернуть`
    //             );
    //             $('.interior__button').addClass('open');
    //             $('.interior__button').next().addClass('active');
    //         }
    //     });
    //     return false;
    // });
})
