$(() => {
    $('.configuration__content').hide();
    $('.configuration__content').first().show();
    $('.configuration__tabs span').first().addClass('active');
    $('.configuration__tabs span').click(function() {
        link = $(this).attr('data');
        $('#' + link).fadeIn(700);
        $('#' + link).siblings('div').hide();
        $(this).addClass('active');
        $(this).siblings('.configuration__tabs span').removeClass('active');
    });
});
