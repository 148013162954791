$(() => {
    const $autoSlider = $(".js-slider-auto");
    const arrows = $autoSlider.data("arrows").split(",");

    if(!!$autoSlider.length) {
        $autoSlider.slick({
            slidesToShow: 1,
            arrows: true,
            infinite: true,
            centerMode: true,
            dots: true,
            // variableWidth: true,
            prevArrow: `<button type="button" class="slick-prev"><svg><use xlink:href="${arrows[0]}"></use></svg></button>`,
            nextArrow: `<button type="button" class="slick-next"><svg><use xlink:href="${arrows[1]}"></use></svg></button>`,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        arrows: false,
                    },
                },
            ]
        })
    }
})
