$(() => {
    const button = $('.option__button');
    button.click(function(){
        $('.option__content-hide').slideToggle(0, function(){
            $(this).toggleClass('active');
            button.toggleClass('open');
            if (button.hasClass('open')) {
                button.html(
                    `<svg class="option__icon-close" width="18" height="18">
                        <use xlink:href="dist/img/sprites/sprite.svg#icon-close" />
                    </svg>
                    Свернуть`
                );
            } else {
                button.html(
                    `<svg class="option__icon-plus" width="18" height="18">
                        <use xlink:href="dist/img/sprites/sprite.svg#icon-plus" />
                    </svg>
                    Показать все опции`
                );
            }
        });
        return false;
    });
})
